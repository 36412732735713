import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import RouteError from "../RouteError/RouterError";

const router = createBrowserRouter([
  {
    path: "/",
    element: <div>home</div>,
    errorElement: <RouteError />,
  },
  {
    path: "/portfolio",
    element: <div>port</div>,
    errorElement: <RouteError />,
    // children: [
    //     {
    //       path: "contacts/:contactId",
    //       element: <Portfolio />,
    //     },
    //   ],
  },
]);

const Router = () => {
return (
    <RouterProvider router={router} />
);
}

export default Router;