import { useRouteError } from "react-router-dom";

const RouteError = () => {
  const error = useRouteError() as any;
  console.error(error);

  return (
    <>
      <h1>Page does not exist</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      {error?.statusText || error?.message}
    </>
  );
}

export default RouteError