import React from 'react';

import Router from '../Router/Router';

import './App.css';

function App() {
  return (
    <>
      ALEXANDRE MILLER
      <Router />
    </>
  );
}

export default App;
